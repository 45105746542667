<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pt-1 pb-1">
        <h5 class="mb-0 flex-grow-1 text-primary">
          Thêm thuộc tính mới
        </h5>
      </b-card-header>
      <div class="card-body">
        <b-row>
          <b-col
              cols="12"
              lg="6"
          >
            <dynamic-form
                :id="'filter-form-acreage'"
                :value="attribute.name"
                placeholder="Tên"
                :label="'Tên thuộc tính'"
                @update-value="val => attribute.name = val"
            />
          </b-col>
          <b-col
              cols="12"
              lg="6"
              class="d-flex justify-content-end align-items-end"
          >
            <b-button
                variant="primary"
                size="sm"
                @click="handleAddNewAttribute(attribute)"
            >
              Thêm mới
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!--    table-->

    <b-overlay
        id="overlay-bg-product-list"
        class="custom-overlay-icon-top"
        :show="loading"
        variant="white"
        opacity=".5"
        blur="2px"
        rounded="sm"
    >
      <b-card
          no-body
          class="mb-0 pb-0"
      >
        <div class="py-1 px-2 d-flex justify-content-between">
          <div class="text-primary" v-html="'Danh sách thuộc tính'"/>
        </div>

        <div class="card-body">
          <b-row>
            <b-col
                cols="12"
                lg="6"
                class="d-flex"
                v-for="(attr, attrIdx) in attributes"
                :key="'attr-' + attrIdx"
            >
              <b-card
                  no-body
                  class="border border-primary w-100"
              >
                <b-card-header class="pt-1 pb-1 border-color-primary">
                  <h5 class="mb-0 flex-grow-1 text-primary font-weight-bolder">
                    {{ attr.name }}
                  </h5>
                  <div class="d-flex gap-1 align-items-center">
                    <b-button
                        variant="danger"
                        size="sm"
                        @click="handleRemoveAttribute(attr)"
                    >
                      <feather-icon icon="XIcon"/>
                    </b-button>
                  </div>
                </b-card-header>

                <div>
                  <b-table
                      class="position-relative custom-head thead-no-wrap line-height-12 font-small-4"
                      responsive
                      hover
                      show-empty
                      empty-text="Không có thuộc tính con"
                      :items="attr.list"
                      :fields="attributeTable"
                  >

                    <template #cell(name)="data">
                      <div class="text-primary">{{ data.item.name }}</div>
                    </template>

                    <template #cell(type)="data">
                      <div class="text-primary">{{ data.item.type }}</div>
                    </template>

                    <template #cell(require)="data">
                      <div :class="data.item.require ? 'text-danger' : ''">
                        {{ data.item.require ? 'Bắt buộc' : 'Tùy chọn' }}
                      </div>
                    </template>

                    <template #cell(actions)="data">
                      <b-button
                          variant="outline-danger"
                          size="sm"
                          @click="handleRemoveAttributeChild(attr, data.item)"
                      >
                        <feather-icon icon="XIcon"/>
                      </b-button>
                    </template>

                  </b-table>

                  <div class="d-flex justify-content-end pr-2 pb-2">
                    <b-button
                        variant="primary"
                        size="sm"
                        @click="openModalAddNewAttributeChild(attr)"
                    >
                      <feather-icon icon="PlusIcon"/>
                    </b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
        id="modal-create-campaign"
        ref="modal-create-campaign"
        cancel-title="Hủy"
        ok-title="Thêm thuộc tính mới"
        size="lg"
        :no-close-on-backdrop="true"
        centered
        header-text-variant="primary"
        title="Thêm thuộc tính mới"
        @ok="handleAddNewAttributeChild(attribute, attributeChild)"
    >
      <b-card-text>
        <b-row>
          <b-col
              cols="12"
          >
            <dynamic-form
                :id="'product-foarm-tinh'"
                :value="attributeChild.name"
                :type="'text'"
                :label="'Tên thuộc tính'"
                @update-value="val => attributeChild.name = val"
            />
          </b-col>

          <b-col
              cols="12"
          >
            <dynamic-form
                :id="'filter-form-hinh-asfgthuc'"
                :value="attributeChild.type"
                :type="'radio'"
                :label="'Loại'"
                :options="[{text: 'Văn bản', value: 'text'},{text: 'Tài liệu đính kèm', value: 'document'},{text: 'Hình ảnh', value: 'image'}]"
                @update-value="val => attributeChild.type = val"
            />
          </b-col>

          <b-col
              cols="12"
          >
            <dynamic-form
                :id="'filter-form-hinh-asaasffthuc'"
                :value="attributeChild.require"
                :type="'radio'"
                :label="'Bắt buộc nhập'"
                :options="[{text: 'Bắt buộc', value: true},{text: 'Tùy chọn', value: false}]"
                @update-value="val => attributeChild.require = val"
            />
          </b-col>
        </b-row>
        <br>
      </b-card-text>
    </b-modal>

    <actions
        :buttons="actionsButton"
        @click="handleActionsButton"
    />
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCol, BMediaAside, BPagination, BRow, BTable, VBTooltip, BBadge, BButton, BOverlay,
  BCardText, BFormGroup, BInputGroup, BFormInput, BCardHeader, BFormDatepicker,
} from 'bootstrap-vue'
import store from '@/store'
import {onUnmounted} from '@vue/composition-api'
import vSelect from 'vue-select'
import campaignStoreModule from '@/views/campaign/campaignStoreModule'
import productStoreModule from '@/views/product/productStoreModule'
import TableColumnToggle from '@core/components/TableColumnToggle.vue'
import ability from '@/libs/acl/ability'
import {subject} from '@casl/ability'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import flatPickr from 'vue-flatpickr-component'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import useDocument from './useDocument'
import Filters from './Filters.vue'

export default {
  components: {
    BCardHeader, BFormInput, BInputGroup, BFormDatepicker,
    DynamicForm,
    Filters,
    BAvatar,
    BCard,
    BTable,
    TableColumnToggle,
    BPagination,
    vSelect,
    BRow,
    BCol,
    BCardText,
    BMediaAside,
    BBadge,
    BOverlay,
    BButton,
    Actions,
    BFormGroup,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      ability,
      subject,
      attributeTable: [
        {
          key: 'name',
          label: 'Tên',
        },
        {
          key: 'type',
          label: 'Dạng dữ liệu',
        },
        {
          key: 'require',
          label: 'Bắt buộc',
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'd-flex justify-content-end pr-2',
        },
      ],
    }
  },
  computed: {
    actionsButton() {
      return []
    },
  },
  watch: {
  },
  created() {
    const {filter} = this.$route.query
    Object.assign(this.filter, JSON.parse(filter || '""'))
  },
  methods: {
    handleAddNewAttribute(attr) {
      this.attributes.unshift({...attr, id: Math.floor(Math.random() * 10000) + 1, list: []})
      this.cleanCurrentAttribute()
      this.setLocalStorage('attributes', this.attributes)
    },
    handleRemoveAttribute(attr) {
      this.attributes = this.attributes.filter(item => item.id !== attr.id)
      this.setLocalStorage('attributes', this.attributes)
    },
    handleAddNewAttributeChild(attr, child) {
      const foundAttr = this.attributes.find(item => item.id === attr.id)
      if (!foundAttr) return
      foundAttr.list.unshift({...child, id: Math.floor(Math.random() * 10000) + 1})
      this.cleanCurrentAttributeChild()
      this.setLocalStorage('attributes', this.attributes)
    },
    handleRemoveAttributeChild(attr, child) {
      const foundAttr = this.attributes.find(item => item.id === attr.id)
      if (!foundAttr) return
      foundAttr.list = foundAttr.list.filter(item => item.id !== child.id)
      this.setLocalStorage('attributes', this.attributes)
    },
    openModalAddNewAttributeChild(attr) {
      this.attribute = {...attr}
      this.$refs['modal-create-campaign'].show()
    },
    cleanCurrentAttribute() {
      this.attribute = {
        name: '',
        id: '',
        child: [],
      }
    },
    cleanCurrentAttributeChild() {
      this.attributeChild = {
        name: '',
        type: '',
        require: '',
      }
    },
    gotoProductEdit(product) {
      this.$router.push({
        name: `product-${this.productMenuType}-edit`,
        params: {id: product.id},
      })
    },
    handleActionsButton(btn) {
      if (btn.value === 'create-campaign') {
        this.$refs['modal-create-campaign'].show()

        // set default creator_id
        this.setUserCreateCampaign()

        // set default distribute_users
        this.setDistributeUsersCreateCampaign()
      }
    },
    handleCreateCampaign() {
      this.$call(this.createCampaign(), true)
    },
    openModalConfirmDeleteProduct(product) {
      this.currentProduct = product
      this.$refs['modal-confirm-delete-product'].show()
    },
    handleDeleteProduct() {
      this.$call(this.deleteProduct(this.currentProduct.id), true)
          .then(() => {
            const foundIdx = this.products.findIndex(item => item.id === this.currentProduct.id)
            if (foundIdx !== -1) {
              this.products.splice(foundIdx, 1)
            }
          })
    },
    gotoProductDetail({id}) {
      const route = this.$router.resolve({
        name: `product-${this.productMenuType}-detail`,
        params: {id},
      })
      window.open(route.href, this.$store.state.app.isDev ? '_self' : '')
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify(this.filter || ''),
        },
      })
      this.fetchProducts()
          .finally(() => {
            this.loading = false
          })
    },
    handleClearFilter(initFilter) {
      this.clearFilter(initFilter)
    },
    updateTableColumns(tableColumns) {
      this.tableColumns = tableColumns
    },
    rowClicked(row) {
      this.gotoProductDetail(row)
    },
  },
  mounted() {
    this.cleanCurrentAttribute()
    this.cleanCurrentAttributeChild()
  },
  setup() {
    const CAMPAIGN_STORE_MODULE_NAME = 'campaign'
    const PRODUCT_STORE_MODULE_NAME = 'product'
    if (!store.hasModule(CAMPAIGN_STORE_MODULE_NAME)) store.registerModule(CAMPAIGN_STORE_MODULE_NAME, campaignStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CAMPAIGN_STORE_MODULE_NAME)) store.unregisterModule(CAMPAIGN_STORE_MODULE_NAME)
    })

    if (!store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.registerModule(PRODUCT_STORE_MODULE_NAME, productStoreModule)
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_STORE_MODULE_NAME)
    })

    return {
      ...useDocument(),
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';

.attr-child-wrapper {
  display: flex;
  gap: 1rem;
  padding: .5rem 0;

  > *:first-child {
    flex-grow: 1;
  }

  > * {
    min-width: 100px;
  }
}
</style>
